import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostInfo from "../components/PostInfo"
import { rhythm } from "../utils/typography"
import ShareButtons from "../components/ShareButtons"

function BlogPostTemplate(props) {
  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <PostInfo
        timeToRead={post.timeToRead}
        title={post.frontmatter.title}
        url={post.fields.slug}
        date={post.frontmatter.date}
      />
      {post.frontmatter.tags && (
        <div css={{ margin: "10px 0" }}>
          <small>
            tags:{" "}
            <b>
              <i css={{ textTransform: "uppercase", fontSize: 11 }}>
                {post.frontmatter.tags.join(", ")}
              </i>
            </b>
          </small>
        </div>
      )}
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <div
          css={{
            marginTop: 30,
            marginBottom: 18,
            width: 50,
            fontSize: 4,
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>●</div>
          <div>●</div>
          <div>●</div>
        </div>
        <ShareButtons postTitle={post.frontmatter.title} />
        <div
          css={{
            fontSize: "12px",
            textAlign: "center",
            width: "100%",
            display: "inline-block",
            opacity: "0.5",
          }}
        >
          Sharing is caring
        </div>
      </div>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Bio />
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      timeToRead
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`
