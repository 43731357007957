import React from "react"
import { Link } from "gatsby"

export default function PostInfo({ timeToRead, url, title, date }) {
  return (
    <div>
      <div>
        <small>{timeToRead} min read</small>
      </div>
      <h3 className="index-post-title" css={{ margin: 0 }}>
        <Link style={{ boxShadow: `none` }} to={url}>
          {title}
        </Link>
      </h3>
      <div css={{ lineHeight: 1 }}>
        <small>{date}</small>
      </div>
    </div>
  )
}
